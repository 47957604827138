var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"appNavbar"}},[_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","color":"#fffcf5"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":"","py-2":"","text-uppercase":"","align-self-center":""}},_vm._l((_vm.navItems),function(main,i){return _c('v-list',{key:i,attrs:{"light":"","dense":""}},[(main.subMenu)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","py-2":""}},[_c('v-list-group',{attrs:{"no-action":"","value":false,"active-class":"activated"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{staticStyle:{"font-size":"12px","letter-spacing":"2px","color":"black","cursor":"pointer","font-family":"poppinsregular"}},[_c('span',{staticClass:"text-none"},[_vm._v(_vm._s(main.name))])])]},proxy:true}],null,true)},[_vm._l((main.subMenu),function(sub,i){return [_c('v-flex',{key:i,attrs:{"xs12":"","text-left":"","pl-6":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-link',{attrs:{"to":sub.route}},[_c('span',{staticStyle:{"color":"black","letter-spacing":"3px","cursor":"pointer"},style:(_vm.$route.path == sub.route
                                    ? {
                                        'font-family': 'poppinssemibold',
                                        'font-size': ' 12px',
                                      }
                                    : {
                                        'font-family': 'poppinsregular',
                                        'font-size': ' 12px',
                                      })},[_c('span',{staticClass:"text-none"},[_vm._v(" > "+_vm._s(sub.name)+" ")])])])],1)],1)],1)]})],2)],1)],1):_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list-item-group',[_c('v-list-item',{staticClass:"py-2",attrs:{"ripple":false}},[_c('v-list-item-content',[_c('router-link',{attrs:{"to":main.route}},[[_c('v-list-item-title',{staticStyle:{"text-align":"left"},style:(_vm.$route.path == main.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                      'font-size': ' 16px',
                                    }
                                  : {
                                      'font-family': 'poppinssemibold',
                                      'font-size': ' 13px',
                                      'letter-spacing': '2px',
                                      cursor: 'pointer',
                                      color: 'black;',
                                    }),domProps:{"textContent":_vm._s(main.name)}})]],2)],1)],1)],1)],1)],1)],1)}),1)],1)],1),_c('v-app-bar',{staticClass:"hidden-lg-and-up",attrs:{"app":"","dark":"","color":"#005f32","dense":"","flat":"","height":"50px"}},[_c('v-app-bar-nav-icon',{nativeOn:{"click":function($event){_vm.sideNav = !_vm.sideNav}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-menu")])],1),_c('v-toolbar-title',[_c('router-link',{attrs:{"to":"/"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-img',{attrs:{"contain":"","height":"50px","src":require('./../../public/wtilogo.png')}})],1)],1)],1)],1)],1),_c('v-app-bar',{staticClass:"hidden-md-and-down",attrs:{"color":"#005f32","elevation":"0","height":"70px"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","fill-height":""}},[_c('v-flex',{attrs:{"xs2":"","align-self-center":""}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"contain":"","height":"50px","src":require('./../../public/wtilogo.png')}})],1)],1),_c('v-flex',{attrs:{"align-self-center":""}},[_c('span',{staticStyle:{"color":"#ffffff","letter-spacing":"2.5px","font-family":"poppinsbold"}},[_vm._v("FUNDRAISING SOFTWARE")])]),_c('v-flex',{attrs:{"xs6":"","lg1":"","xl1":"","px-4":"","text-center":"","align-self-center":""}},[_c('AccountMenu')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }