<template>
  <div>
    <AppHeader />
    <v-divider></v-divider>
    <v-layout wrap justify-center fill-height>
      <v-flex xs12 sm12 md12 lg2 hidden-md-and-down>
        <v-navigation-drawer
          class="fixedSidebar"
          v-model="sideNav"
          clipped
          overlay-color="yellow"
          width="100%"
          height="100vh"
          permanent
          color="#F5F5F5"
        >
          <v-layout wrap>
            <v-flex xs12>
              <v-card
                style="
                  color: #ffffff;
                  letter-spacing: 2.5px;
                  font-family: poppinsbold;
                "
                elevation="0"
                tile
                color="grey"
                class="white--text py-1"
              >
                {{ appType }} 
              </v-card>
            </v-flex>
          </v-layout>
          <v-list>
            <v-list-item-group v-model="model">
              <v-list-item class="pb-3" v-for="(item, i) in navItems" :key="i">
                <v-list-item-icon>
                  <router-link :to="item.route" style="text-decoration: none">
                    <v-icon color="#005f32" x-samll v-text="item.icon"></v-icon>
                  </router-link>
                </v-list-item-icon>

                <v-list-item-content>
                  <router-link :to="item.route" style="text-decoration: none">
                    <v-list-item-title 
                      style="
                        font-size: 14px;
                        letter-spacing: 2px;
                        color: black;
                        cursor: pointer;
                        font-family: poppinssemibold;
                        text-align:left;
                      "
                      v-text="item.name"
                    ></v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md12 lg10 pt-12 pt-lg-0>
        <v-layout
          wrap
          justify-center
          :style="
            $route.name == 'Dashboard'
              ? 'background-color: #FFFFFF'
              : 'background-color: #fff9c4'
          "
        >
          <v-flex xs12 pa-0>
            <v-card
              min-height="100vh"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout wrap justify-center>
                <v-flex xs12 pa-4>
                  <router-view :key="$route.fullPath"></router-view>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import AppHeader from "./../layout/AppHeader";
export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      drawer: false,
      nav: false,
       model:null,
      menus: [
        {
          name: "Dashboard",
          route: "/admin",
          icon: "mdi-view-dashboard",

          // subMenu: [
          //   {
          //     name: "board",
          //     route: "/admin",
          //   },
          // ],
        },

        {
          name: "Divisions",
          route: "/division",
          icon: "mdi-image-filter-hdr",
        },
        {
          name: "Snare Types",
          route: "/manageSnare",
          icon: "mdi-nix",
        },
        {
          name: "User List",
          route: "/userlist",
          icon: "mdi-account",

          // subMenu: [
          //   {
          //     name: "User List",
          //     route: "/userlist",
          //   },
          // ],
        },
        {
          name: "Reportings",
          route: "/reportings",
          icon: "mdi-page-layout-sidebar-left",

          // subMenu: [
          //   {
          //     name: "Reportings",
          //     route: "/reportings",
          //   },
          // ],
        },
        {
          name: "Trainings",
          route: "/trainings",
          icon: "mdi-run",

          // subMenu: [
          //   {
          //     name: "Trainings",
          //     route: "/trainings",
          //   },
          // ],
        },
        {
          name: "Animal Rescue",
          route: "/animalrescue",
          icon: "mdi-paw",

          // subMenu: [
          //   {
          //     name: "Animal rescue",
          //     route: "/animalrescue",
          //   },
          // ],
        },

        {
          name: "Privacy Policy",
          route: "/privacy",
          icon: "mdi-note",

          // subMenu: [
          //   {
          //     name: "Privacy Policy",
          //     route: "/privacy",
          //   },
          // ],
        },
      ],

      sideNav: true,
      admin: [{ name: "ADMINS", route: "/Admins" }],

      dashMenus: [],
      FinanacMenus: [
        {
          icon: "mdi-view-dashboard",
          name: "Dashboard",
          route: "/",
          // subMenu: [
          //   {
          //     name: "Dashboard",
          //     route: "/",
          //   },
          // ],
        },
        {
          icon: "mdi-page-layout-sidebar-left",
          name: "Projects",
          route: "/Projects",
          // subMenu: [
          //   {
          //     name: "project",
          //     route: "/Projects",
          //   },
          // ],
        },
        {
          icon:" mdi-cash-multiple",
          name: "Pending Fund",
          route: "/PendingFund",
          // subMenu: [
          //   {
          //     name: "Fund List",
          //     route: "/PendingFund",
          //   },
          // ],
        },
        {
          icon: "mdi-account",
          name: "Employee",
          route: "/UserList",
          // subMenu: [
          //   {
          //     name: "Employee List",
          //     route: "/UserList",
          //   },
          // ],
        },
      ],
      SuperAdminMenus: [
        {
          icon: "mdi-view-dashboard",
          name: "Dashboard",
          route: "/",
          // subMenu: [
          //   {
          //     name: "Dashboard",
          //     route: "/",
          //   },
          // ],
        },
        {
          icon: "mdi-domain",
          name: "Organisation Type",
          route: "/OrganisationType",
          // subMenu: [
          //   {
          //     name: "Organisation Type",
          //     route: "/OrganisationType",
          //   },
          // ],
        },
        {
          icon: "mdi-domain",
          name: "Donor’s/Partners",
          // name: "Organisations",
          route: "/OrganisationList",
          // subMenu: [
          //   {
          //     name: "Organisations",
          //     route: "/OrganisationList",
          //   },
          // ],
        },
        {
          icon: "mdi-page-layout-sidebar-left",
          name: "Projects",
          route: "/Projects",
          // subMenu: [
          //   {
          //     name: "project",
          //     route: "/Projects",
          //   },
          // ],
        },
        {
          icon: "mdi-account",
          name: "Donor Point Person",
          route: "/UserList",
          // subMenu: [
          //   {
          //     name: "Employee List",
          //     route: "/UserList",
          //   },
          // ],
        },
      ],
    };
  },
  computed: {
    appType() {
      return this.$store.state.userType;
    },

    navItems() {
      if (this.appType == "Finance") return this.FinanacMenus;
      else if (this.appType == "SuperAdmin") return this.SuperAdminMenus;
      else if (this.appType == "staticcontentadmin")
        return this.userNavItemsStaticAdmin;
      else if (this.appType == "accountsadmin")
        return this.userNavItemsAccountsAdmin;
      else return this.userNavItems;
    },
  },
};
</script>
<style>
@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    /* position: fixed; */

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
/* .activated { */
.activated {
  color: #005f32 !important;
}
/* } */
</style>
