<template>
  <div id="appNavbar">
    <v-navigation-drawer v-model="sideNav" fixed temporary color="#fffcf5">
      <v-layout wrap>
        <v-flex xs12 text-left py-2 text-uppercase align-self-center>
          <!-- <v-list dense v-for="main in appNavItems" :key="main._id">
            <v-layout wrap>
              <v-flex xs12 py-2>
                <v-list-group
                  no-action
                  :value="false"
                  active-class="activated"
                >
                  <template v-slot:activator>
                    <v-list-item-title
                      style="
                        font-size: 13px;
                        letter-spacing: 2px;
                        color: black;
                        cursor: pointer;
                        font-family: poppinssemibold;
                      "
                      >{{ main.name }}</v-list-item-title
                    >
                  </template>
                  <v-flex xs12 py-2 px-4>
                    <div style="border-bottom: 1px solid black"></div>
                  </v-flex>
                  <template v-for="sub in main.subMenu">
                    <v-flex xs12 text-left pl-4 :key="sub._id">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="sub.route">
                            <span
                              :style="
                                $route.path == sub.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                      'font-size': ' 16px',
                                    }
                                  : {
                                      'font-family': 'poppinssemibold',
                                      'font-size': ' 13px',
                                    }
                              "
                              style="
                                color: black;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                            >
                              {{ sub.name }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2 px-4 :key="sub._d">
                      <div style="border-bottom: 1px solid black"></div>
                    </v-flex>
                  </template>
                </v-list-group>
                
              </v-flex>
            </v-layout>
         
          </v-list> -->
            <v-list light dense v-for="(main, i) in navItems" :key="i">
                <v-layout wrap v-if="main.subMenu">
                  <v-flex xs12 py-2>
                    <v-list-group
                      no-action
                      :value="false"
                      active-class="activated"
                    >
                      <template v-slot:activator>
                        <v-list-item-title
                          style="
                            font-size: 12px;
                            letter-spacing: 2px;
                            color: black;
                            cursor: pointer;
                            font-family: poppinsregular;
                          "
                        >
                          <span class="text-none">{{
                            main.name
                          }}</span></v-list-item-title
                        >
                      </template>

                      <template v-for="(sub, i) in main.subMenu">
                        <v-flex xs12 text-left pl-6 :key="i">
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <router-link :to="sub.route">
                                <span
                                  :style="
                                    $route.path == sub.route
                                      ? {
                                          'font-family': 'poppinssemibold',
                                          'font-size': ' 12px',
                                        }
                                      : {
                                          'font-family': 'poppinsregular',
                                          'font-size': ' 12px',
                                        }
                                  "
                                  style="
                                    color: black;
                                    letter-spacing: 3px;
                                    cursor: pointer;
                                  "
                                >
                                  <span class="text-none">
                                    > {{ sub.name }}
                                  </span>
                                </span>
                              </router-link>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </template>
                    </v-list-group>
                    <!-- <v-list-group v-else> -->
                  </v-flex>
                </v-layout>
                <v-layout wrap v-else>
                  <v-flex xs12>
                    <v-list-item-group>
                      <v-list-item class="py-2" :ripple="false">
                        <v-list-item-content>
                          <router-link :to="main.route">
                            <template>
                              <v-list-item-title 
                              style="text-align:left"
                                :style="
                                  $route.path == main.route
                                    ? {
                                        'font-family': 'poppinsbold',
                                        'font-size': ' 16px',
                                      }
                                    : {
                                        'font-family': 'poppinssemibold',
                                        'font-size': ' 13px',
                                        'letter-spacing': '2px',
                                        cursor: 'pointer',
                                        color: 'black;',
                                      }
                                "
                                v-text="main.name"
                              ></v-list-item-title>
                            </template>
                          </router-link>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-flex>
                </v-layout>

                <!-- </v-list-group> -->
              </v-list>



        </v-flex>
      </v-layout>
     
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      color="#005f32"
      dense
      flat
      height="50px"
      class="hidden-lg-and-up"
    >
      <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
        <v-icon color="white">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/">
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-img
                contain
                height="50px"
                :src="require('./../../public/wtilogo.png')"
              ></v-img>
              
            </v-flex>
          </v-layout>
        </router-link>
      </v-toolbar-title>
    </v-app-bar>
    <v-app-bar
      color="#005f32"
      elevation="0"
      height="70px"
      class="hidden-md-and-down"
    >
      <v-layout wrap justify-center fill-height>
        <v-flex xs2 align-self-center>
          <router-link to="/">
            <v-img
              contain
              height="50px"
              :src="require('./../../public/wtilogo.png')"
            ></v-img>
          
          </router-link>
        </v-flex>
        <!-- <v-spacer></v-spacer> -->
        <v-flex align-self-center>
          <span style="color:#ffffff;letter-spacing: 2.5px;font-family:poppinsbold">FUNDRAISING SOFTWARE</span>
        </v-flex>
        <v-flex xs6 lg1 xl1 px-4 text-center align-self-center>
          <AccountMenu />
        </v-flex>
      </v-layout>
    </v-app-bar>
  </div>
</template>
<script>
import AccountMenu from "./../components/accountMenu";
export default {
  components: {
    AccountMenu,
  },
  data() {
    return {
      FinanacMenus: [
        {
          name: "Dashboard",
          route: "/",
         
        },
        {
          name: "Projects",
          route: "/Projects",
         
        },
        {
          name: "Pending Fund",
          route: "/PendingFund",
         
        },
        {
          name: "Employee",
          route: "/UserList",
         
        },
      ],
      SuperAdminMenus: [
        {
          name: "Dashboard",
          route: "/",
         
        },
        {
          name: "Organisation Type",
          route: "/OrganisationType",
         
        },
        {
          name: "Organisations",
          route: "/OrganisationList",
          
        },
        {
          name: "Projects",
          route: "/Projects",
          
        },
        {
          name: "Employee",
          route: "/UserList",
          
        },
      ],
      sideNav: false,
      admin: [{ name: "ADMINS", route: "/Admins" }],
      menus: [
        {
          name: "Dashboard",
          subMenu: [
            {
              name: "dashboard",
              route: "/",
            },
          ],
        },

        
        {
          name: "Organisations",
          subMenu: [
            {
              name: "Organisations",
              route: "/OrganisationList",
            },
          ],
        },
        {
          name: "Employee",
          subMenu: [
            {
              name: "Employee List",
              route: "/UserList",
            },
          ],
        },
        



      ],
    };
  },
  computed: {
    appUser() {
      return null;
    },
    appMenu() {
      // console.log("menus", store.state.menus);
      return this.$store.state.menus;
    },
    appLogin() {
      return false;
    },
    appType() {
      return this.$store.state.userType;
    },
    appNavItems() {
      return this.menus;
    },

     navItems() {
      if (this.appType == "Finance") return this.FinanacMenus;
      else if (this.appType == "SuperAdmin") return this.SuperAdminMenus;
      else if (this.appType == "staticcontentadmin")
        return this.userNavItemsStaticAdmin;
      else if (this.appType == "accountsadmin")
        return this.userNavItemsAccountsAdmin;
      else return this.userNavItems;
    },
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: poppinsregular !important;
}
input::-moz-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: poppinsregular !important;
}
.searchBox .v-input__control {
  min-height: 10px !important;
}
.searchBox .v-input__control .v-input__slot {
  padding: 0px !important;
}
</style>
